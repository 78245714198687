/* News Single & News Page*/
.news {
  font-family: $font-main-bold;
  padding: 80px 15px;

  &-single {
    width: 66%;
    padding-top: 40px;
    @include breakpoint("mobile") {
      width: 100%;
    }
  }

  &-extra {
    width: 31%;
    background: $color-white;
    padding: 20px 25px;
    margin-top: 40px;
    border-radius: 3px;
    box-shadow: 0 5px 15px rgba(0,0,0,.07);
    @include breakpoint("mobile") {
      width: 100%;
    }
    .news {
      padding-top: 0;
    }
    h2 {
        font-weight: 400;
        font-size: calc(24px + 0.2vw);
        margin: 0 0 14px;
    }
    .news-details {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      margin: 0 0 10px;
    }
    .news-bar {
      box-shadow: unset;
      .news-thumb {
        min-height: 220px;
      }
      .news-infos {
        padding: 10px 7%;
        h4 {
          font-weight: 600;
          font-size: 18px;
          height: auto;
          max-height: 50px;
        }
      }
      .news-details {
        -webkit-line-clamp: 3;
      }
    }
  }

  &-updates {
    font-family: $font-main-bold;
    background: url(../../assets/image/hnewbg.png) no-repeat top center;
    background-attachment: fixed;
  }

  &-page {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint("mobile") {
      flex-direction: column;
    }
    .news-bar {
      width: 32%;
      margin-bottom: 40px;
      &:last-child {
        margin-right: auto;
      }
      &:nth-last-child(2) {
        margin-right: 2%;
      }
      .news-thumb {
        min-height: 270px;
      }
      @include breakpoint("mobile") {
        width: 100%;
      }
    }
    .news-details {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      margin: 0 0 10px;
    }
    .block-title {
        display: none;
        width: 100%;
    }
  }

  &-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0;
    @include breakpoint("mobile") {
        flex-direction: column;
    }
  }

  &-bar {    
    background-color: $color-white;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
    border-radius: 3px;
    @include breakpoint("mobile") {
      max-width: 100%;
      width: 100%;
    }

    &:hover {
      .news-thumb {
        &:before,
        &:after {
          height: 100%;
          opacity: 1;
        }
      }
    }

    .news-thumb {
      position: relative;
      overflow: hidden;
      min-height: 450px;
      @include breakpoint("mobile") {
        min-height: initial;
        }
      a {
        position: absolute;
        z-index: 10;
        display: block;
        height: 100%;
        width: 100%;
        &:before,
        &:after {
          transition: all ease-in-out 0.5s;
          -webkit-transition: all ease-in-out 0.5s;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 0;
          opacity: 0;
          content: "";
          background: rgba(0, 0, 0, 0.3);
        }
        &:before {
          top: inherit;
          bottom: 0;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .news-infos {
      margin: 0 auto;
      min-height: 50px;
      padding: 3vh 10% 5vh;
      h4 {
        padding: 0;
        font-size: 24px;
        font-weight: 400;
        line-height: 1.4;
        margin-bottom: 9px;
        text-align: left;

        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 65px;
        margin: 0 0 10px;
      }
    }
    .news-details {
      margin: 0 auto;
      p {
        color: $color-grey;
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 18px;
        a {
          font-weight: 600;
          color: $color-orange;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 20px 0 18px;
        line-height: 1.5;
      }

      h1,
      h2,
      h3 {
        font-size: 24px;
        font-weight: 500;
      }

      strong {
        font-weight: 700;
      }

      em {
        font-style: italic;
      }

      blockquote {
        width: 90%;
        margin: 30px auto;
        overflow: hidden;
        background: #20273f;
        border-radius: 3px;
        padding: 30px;
        position: relative;
        p {
          margin: 0px;
          color: #fff;
          font-family: "Roboto Condensed", sans-serif;
          font-size: 24px;
          line-height: 32px;
          font-weight: 300;
          font-style: italic;
        }
        &::after {
          position: absolute;
          content: "\f10d";
          @include fontAwesome;
          font-size: calc(100% + 2vw);
          bottom: 20px;
          right: 20px;
          color: rgba(255, 255, 255, 0.07);
          transform: scale(-1, 1);
        }
      }

      img {
        width: 150px;
      }
    }

    .news {
      &-counter,
      &-date {
        text-align: left;
      }

      &-date {
        font-size: 14px;
        color: #888888;
        margin: 0 0 18px;
        i {
          color: #cccccc;
          margin-right: 5px;
        }
      }
    }
  }

  section.newsupdate {
    min-height: 450px;
    overflow-x: hidden;
  }

  .row-news {
    width: 100%;
    .slick-slider {
      position: relative;
    }
    .slick-slide {
      padding: 0 10px;
      > div {
        outline: none;
        border: none;
      }
    }
    .slick-dots {
      top: -30px;
      width: 100px;
      height: 30px;
      right: 30px;
      bottom: unset;

      button:hover::after {
        opacity: 0;
      }
    }
    .slick-arrow {
      &:after {
        opacity: 0;
      }
    }

    .arrow-proxima,
    .arrow-anterior {
      display: block;
      position: absolute;
      top: -50px;
      width: 15px;
      height: 15px;
      &:after {
        color: $color-orange;
        @include fontAwesome;
      }
      @include breakpoint("mobile") {
        top: -30px;
      }
    }
    .arrow-proxima {
      right: 10px;
      &:after {
        content: "\f061";
      }
    }
    .arrow-anterior {
      right: 160px;
      &:after {
        content: "\f060";
      }
    }

    .slick-dots {
      position: absolute;
      top: -53px;
      right: 52px;
      white-space: nowrap;
      @include breakpoint("mobile") {
        top: -32px;
      }
      button {
        &::before,
        &::after {
          font-size: 8px;
        }
      }
      li {
        &.slick-active {
          button {
            &::before,
            &::after {
              color: $color-orange;
            }
          }
        }
      }
    }

    .news-title {
      width: 100%;
      margin: 0 0 40px;
      position: relative;
      line-height: 1.5;
      h2,
      h3 {
        color: $color-main;
        position: relative;
        padding: 0 0 0 22px;
        font-size: calc(20px + 1vw);
        font-weight: 600;
        line-height: 40px;
        &:after {
          background-image: url(../../assets/image/titlelines_o.png);
          position: absolute;
          left: 0;
          top: 0;
          width: 10px;
          height: 37px;
          content: " ";
        }
      }
    }

    .news-card {
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      width: 33.33%;
      background-color: $color-white;
      outline: none;
      border: none;
      .news-thumb {
        position: relative;
        overflow: hidden;
        min-height: 260px;
        &::before,
        &::after {
          content: "";
          position: absolute;
          display: block;
          width: 100%;
          background: rgba(0, 0, 0, 0.3);
          transition: $trs-2;
          opacity: 0;
          height: 0;
        }
        &::before {
          top: 0;
        }
        &::after {
          bottom: 0;
        }
        a {
          display: block;
        }
        .no-image {
          position: relative;
          padding-top: 71%;
        }
        img {
          min-height: 260px;
          width: 100%;
          height: auto;
          transition: all 0.8s;

          @include breakpoint("mobile") {
            min-height: initial;
          }
        }
        i {
          cursor: pointer;
          position: absolute;
          z-index: 5;
          font-size: $fz-2;
          top: 50%;
          left: 30%;
          border-radius: 100%;
          padding: 20px;
          transform: translate(-50%, -50%);
          color: $color-black;
          background: $color-white;
          transition: $trs-2;
          opacity: 0;
          &:hover {
            background: $color-orange;
          }
        }
      }
      .news-infos {
        width: 100%;
        padding: 25px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h4 {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          height: 65px;
          margin: 0 0 10px;
          a {
            font-size: 24px;
            font-weight: 400;
            line-height: 1.4;
            color: $color-main;
            text-transform: unset;
            transition: all 0.8s;
          }
        }        
        .news-brief {
          color: #777777;
          font-size: 14px;
          line-height: 1.4;
          margin: 0 0 25px;
          word-break: break-all;
          height: 60px;
          overflow: hidden;
          // letter-spacing: 0.05em;
        }
        .news-seemore {
          width: 100%;
          a {
            width: auto;
            min-width: 100px;
            line-height: 30px;
            border: 1px solid #e1e1e1;
            display: inline-block;
            padding: 0 20px;
            text-transform: uppercase;
            text-align: center;
            border-radius: 3px;
            color: #bbbbbb;
            font-size: 12px;
            font-weight: 600;
            margin: 0;
            transition: $trs-2;
          }
        }
      }

      &:hover {
        .news-infos h4 a {
          color: $color-orange;
        }
        .news-thumb {
          &::before,
          &::after {
            height: 100%;
            opacity: 1;
            transition: $trs-2;
          }
          i {
            left: 50%;
            opacity: 1;
            transition: $trs-2;
          }
        }
        .news-seemore {
          a {
            color: $color-white;
            background: $color-orange;
            border-color: transparent;
            transition: $trs-2;
          }
        }
      }
    }
  }
}

.page-noticias {
  background: url(../../assets/image/hnewbg.png) no-repeat top center;
  background-attachment: fixed;
}

.newsupdate {
  padding-top: 0;
  margin-bottom: 0;
}
