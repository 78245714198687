.about{
    margin: 30px 0;
    background-color: $color-white;
    padding: 2%;
    font-family: $font-main-bold;
    box-shadow: 0 5px 15px rgba(0,0,0,.10);

   
    .fotos-row{
        margin: 30px auto;
        position: relative;
        .card-thumb{
            img{
                margin: 0 auto;
                height: 460px;     
                @media(max-width: 768px){                    
                    height: 200px;
                }           
            }
        }
    }
    .about-title{
        font-size: 28px;
        font-weight: 400;
        padding: 10px 20px;
        color: $color-orange;
        line-height: 33px;
        text-align: left;
        margin: 10px auto 9px;        
    }
    .about-text{
        font-size: 16px;
        font-family: $font-main;
        line-height: 20px;
        p{
            padding: 10px 20px;
            color: #777777;
            font-size: 16px;
            line-height: 26px;

            strong{
                font-weight: 500;
            }
        }
        ul{
            padding: 10px 20px;
            
            li{
                line-height: 30px;
                font-weight: 500;
            }
        }
    }
}