.page404-component {
  //background: url(../../assets/image/p404bg.jpg);
  background: $color-grey-3;
  .error-404 {
    text-align: center;
    width: 60%;
    margin: 0 auto;
    height: 100%;
    padding-top: 7vh;
    min-height: 100vh;
    h1 {
      font-size: 15vw;
      font-weight: 600;
      color: $color-orange;
      font-family: $font-trd;
    }
    h2 {
      color: #222222;
      font-size: 32px;
      font-family: $font-trd;
      font-weight: 700;
      text-transform: uppercase;
      line-height: 1.5;
      em {
        font-style: italic;
        display: block;
        font-weight: 500;
        text-transform: capitalize;
        margin: 0 0 15px;
      }
    }
    p {
      color: #222222;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.2;
      margin: 10px 0 30px;
    }
  }
}
