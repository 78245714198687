@import "breakpoint";


@mixin page-size {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 1%;
    @include breakpoint ("mobile"){
        padding: 0 4%;
    }
    @include breakpoint ("xs"){
        padding: 0 2%;
    }
}

@mixin page-size-bg {
    max-width: 1180px;
    margin: 0 auto;
    padding: 0;
}

@mixin page-size-full {
    width: 100%;
    @include breakpoint ("mobile"){
        padding: 0;
    }
}

@mixin fontAwesome {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
}