.season {
    max-width: 730px;
    margin: 80px auto;
    
    @media (max-width: 1200px) {
        margin: 40px auto;
        padding: 10px;
        max-width: 750px;
    }

    &-list {

    }
    &-item {
        display: flex;
        flex-direction: row;
        border: 1px solid #e1e1e1;
        background: #fff;
        border-radius: 3px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        padding: 40px;
        margin: 0 0 30px;
        @include breakpoint("mobile") {
            flex-direction: column;
            padding: 20px;
        }
    }
    .team,
    .info {
        padding: 0 15px;
        width: 207px;
        @include breakpoint("mobile") {
            width: 100%;            
        }
    }

    .team,
    .line-mobile {
        @include breakpoint("mobile") {
            order: 1;
        }
    }
    

    .info {
        line-height: 1.5;
        text-align: center;
        @include breakpoint("mobile") {
            margin-bottom: 40px;
        }
        strong {
            display: block;
            color: #e81f3e;
            font-family: $font-main-bold;
            font-size: 16px;
            text-transform: uppercase;
            margin: 0 0 5px;
        }
        p {
            font-size: 14px;
            margin: 0;

            &.brief {
                min-height: 42px;
                @include breakpoint("mobile") {
                    min-height: 32px;
                }
            }
        }
        img {
            margin: 17px 0 14px;
        }
        .line {
            display: block;
            @include breakpoint("mobile") {
                display: none;
            }
        }
    }
    .line-mobile {
        display: none;
        @include breakpoint("mobile") {
            display: block;
            margin: 20px 0;
            margin: 3vh auto;
        }
        @media (min-width: 612px) AND (max-width: 1024px){
            width: 100%;
            max-width: 300px;
        }
    }

    
    .team {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        max-width: 32%;
        @include breakpoint("mobile") {
            max-width: 100%;
        }
        &-info {
            width: 80px;
            text-align: center;

            .team-logo {
                position: relative;
                min-height: 90px;
                @include breakpoint("mobile") {
                    margin-bottom: 20px;
                    min-height: unset;
                    max-height: 70px;
                 }
                img {
                    width: auto;
                    height: auto;
                    min-width: 20px;
                    max-width: 55px;
                    margin: 0 0 12px;
                }

                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    opacity: 0.5;
                    left: 50%;
                    border-radius: 100%;
                    display: block;
                    position: absolute;
                    top: 75%;
                    background-color: transparent;
                    box-shadow: 1px 80px 8px 7px #777777, 1px 80px 10px 15px #b7b7b7;
                    transform-style: preserve-3d;
                    transform: translate(-50%, 0) scaleY(0.1);
                }
            }
            .team-name {
                color: #222222;
                font-family: $font-main-bold;
                // font-size: 20px;
                font-size: calc(10px + 1.1vh);
                font-weight: 400;
                line-height: 24px;
                display: block;
                margin-top: 7px;
                @include breakpoint("mobile") {
                    font-size: 16px;
                    text-transform: uppercase;
                 }
            }
        }
        &-score {
            margin-left: 45px;
            text-align: center;
            color: $color-orange;
            text-transform: uppercase;
            font-size: 14px;
            font-family: $font-main-bold;
            strong {
                width: 50px;
                height: 60px;
                display: block;
                background: $color-yellow;
                text-align: center;
                line-height: 60px;
                color: #222;
                font-family: 'Roboto Condensed', sans-serif;
                font-size: 30px;
                margin-bottom: 10px;
            }
            .win {
                // font-weight: bold;
                color: $color-success;
            }
            .loss {
                color: $color-red;
            }
        }

        .no-image {
            height: 60px;
            width: 60px;
            min-width: auto;
            position: relative;
            margin: 0 auto 12px;
            &::before {
                background: #d4d4d4;
                border: 2px dashed darken($color-yellow, 7%);
                border-radius: 100%;
                width: 100%;
            }
        }
    }

    .score-list {
        margin: 20px auto 0;
        color: #777777;
        font-size: 14px;
        @include breakpoint("mobile") {
            width: 100%;
         }
        li {
            line-height: 1.5;
        }
        &__name {
        }
        &__gols {
            color: $color-red;
        }
    }
    
}