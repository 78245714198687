.hall-component {
    background: $color-main;
    background: url(../../assets/image/teamsec-bg.png) no-repeat;
    background-size: cover;
    padding: 80px 15px;
    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 60px 0;
        @include breakpoint("mobile"){
            flex-direction: column;
        }
        .title {
            font-size: 2rem;
            color: $color-white;
            font-weight: 700;
            vertical-align: middle;
            padding-left: 30px;
            position: relative;
            @include breakpoint("mobile"){
                font-size: 1.5rem;
                margin-bottom: 20px;
                padding-left: 20px;
            }
            &::before {
                content: "";
                display: inline-block;
                width: 15px;
                height: 55px;
                background-image: url(../../assets/image/titlelines_w.png);
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: 0 0;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                @include breakpoint("mobile"){
                    width: 10px;
                    height: 45px;
                }
            }
        }
        .full-team {
            background:$color-yellow;
            height: 50px;
            width: auto;
            display: inline-block;
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.25rem;
            line-height: 40px;
            height: 40px;
            padding: 0 20px;
            border-radius: 5px;
            transition: $trs-2;
            &:hover {
                background: $color-red;
                color: $color-white;
                transition: $trs-2;
            }
            @include breakpoint("mobile"){
                font-size: 0.75rem;
                max-width: 200px;
            }
        }
    }
}

.modal-player {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 6000;
    align-items: center;
    justify-content: center;
    &__wrap {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(15, 15, 15, 0.9);
    }
    &.active{
        display: block;
        transition: $trs;
    }
    &__close {
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
        background: $color-red;
        padding: 10px 15px;
        i {
            display: block;
            font-size: 25px;
            color: #ffffff;
        }
    }

    .player {
        &__tag,
        &__bio  {
            display: none;
        }
        &__card{
            padding: 0;
            height: 520px;
            max-width: 880px;
            width: 100%;
            background-color: #fff;
            background-image:url(../../assets/image/socericon.png);
            background-size: 190px;
            background-repeat: no-repeat;
            background-position: right -30px bottom -30px;
            @include breakpoint("mobile"){
                height: 100%;
                margin-bottom: 0;
                max-width: 100%;
            }
            li {
                color: #777777;
                font-size: $fz-2;
            }
            p {
                &:nth-of-type(1) {
                    min-width: 100px;
                }
                &:nth-of-type(2){
                    color: $color-red;
                    font-weight: 700;
                }
            }
            .colon {
                display: inline;
                margin: 0 20px 0 5px;
            }
            &:hover {
                .player {
                    &__name {
                        color: $color-black;
                    }
                    &__position {
                        color: $color-yellow;
                    }
                }
            }
        }
        &__image{
            height: 100%;
            align-items: initial;
            max-width: inherit;
            justify-content: center;
            background: linear-gradient(135deg, rgba(32, 39, 63, 0.8), rgba(232, 31, 62, 0.8));
            img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
                height: inherit;
            }
            .no-image {
                position: relative;
                min-width: 260px;
            }
        }
        &__name {
            font-size: 1.75rem;
            font-weight: 900;
            height: auto;
            margin-bottom: 20px;
            max-width: 85%;
            max-height: 80px;
            @include breakpoint("mobile"){
                font-size: 1.25rem;
                margin-bottom: 10px;
            }
        }
        &__info {
            padding: 5% 5% 1% 3%;
            color: $color-black;
            background: none;
            min-width: 420px;
            @include breakpoint("mobile"){
                min-width: 300px;
                position: relative;
            }
        }
        &__career {
            flex-direction: column;
            max-height: 340px;
            overflow: auto;
            li {
                border: none;
                line-height: 1.4;
                padding-left: 0;
                p {
                    display: inline-block;
                }
            }
            .type-modal {
                display: block;
            }
        }
        &__follow {
            top: 37px;
            font-weight: 300;
            a {
                font-size: $fz;
            }
            @include breakpoint("mobile"){
                top: 17px;
            }
        }
        &__words {
            color: $color-black;
            height: auto;
            max-height: 48px;
            overflow-y: auto;
            margin-bottom: 20px;
            @include breakpoint("mobile"){
                margin-bottom: 10px;
            }
        }
    }
}


.player {
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        @include breakpoint("sm"){
            flex-direction: column;
        }
    }
    &__card {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        min-height: 300px;
        width: 48%;
        @include breakpoint("mobile"){
            position: relative;
        }
        &:hover{
            .player {
                &__position {
                    color: $color-red;
                    transition: $trs-2;
                }
                &__bio {
                    span {
                        color: $color-yellow;
                        transition: $trs-2;
                    }
                }
                &__follow {
                    a{
                        background: $color-yellow;
                        transition: $trs-2;
                    }
                }
                &__name {
                    color: $color-yellow;
                    transition: $trs-2;
                }
                &__info {
                    &::before {
                        opacity: 0.4;
                        bottom: 0;
                        transition: $trs;
                    }
                }
            }
        }
        @include breakpoint("sm"){
            flex-direction: column;
            width: 100%;
            margin-bottom: 40px;
            .player {
                &__info {
                    &::before {
                        opacity: 0.4;
                        height: 240px;
                        transition: $trs;
                    }
                }
            }
        }
        .colon {
            display: none;
        }
    }
    &__image,
    &__info {
        height: 100%;
    }
    &__image {
        position: relative;
        display: flex;
        align-items: center;
        width: 40%;
        height: 100%;
        max-width: 240px;
        min-height: 280px;
        background: linear-gradient(135deg, rgba($color-blue, 0.3), rgba($color-red, 0.3));
        @include breakpoint("mobile"){
            max-width: initial;
            width: 100%;
        }
        img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 0;
        } 
    }
    &__info {
        padding: 5% 8% 1% 4%;
        color: $color-white;
        background: $color-blue-2;
        position: relative;
        z-index: 5;
        // min-width: 240px;
        min-height: 280px;
        max-width: 300px;
        min-width: 220px;
        width: 60%;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        // &::before {
        //     content: "";
        //     width: 100%;
        //     background: linear-gradient(transparent, #000 3%);
        //     opacity: 0;
        //     position: absolute;
        //     height: 200px;
        //     bottom: -100px;
        //     z-index: 0;
        //     transition: $trs-2;
        // }
        @include breakpoint("mobile"){
            position: static;
            padding: 10% 8% 4% 4%;
            max-width: initial;
            width: 100%;
        }
    }
    &__tag {
        position: absolute;
        z-index: 10;
        top: 0;
        right: 6%;
        display: block;
        width: 25px;
        height: 35px;
        background-image: url(../../assets/image/player-tag.png);
        background-repeat: no-repeat;
        background-size: contain;
        text-align: center;
        i {
            position: relative;
            top: 5px;
        }
    }
    &__name {
        font-size: 1.25rem;
        font-weight: 400;
        margin-bottom: 10px;
        height: 60px;
        overflow-y: auto;
        line-height: 1.4;
    }
    &__position {
        transition: $trs-2;
        color: $color-yellow;
        text-transform: uppercase;
        font-size: $fz-2;
        font-weight: 700;
        margin-bottom: 15px;
    }
    &__words {
        color: $color-grey;
        font-size: $fz;
        height: 65px;
        overflow-y: auto;
        letter-spacing: 0.05em;
        line-height: 1.5;
        display: flex;
    }
    &__career {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;
        margin-bottom: 10px;
        li {
            line-height: 30px;
            color: #ccc;
            font-size: $fz-2;
            border-left: 1px solid #3b4156;
            padding-left: 7px;
            font-weight: 300;
            font-family: $font-main-bold;

            p {
                &:first-child{
                    margin-bottom: 5px;
                }
            }
        }
        .type-modal {
            display: none;
        }
    }
    &__engagement {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @include breakpoint("mobile"){
            margin-top: 40px;
            flex-direction: column;
        }
        a, span {
            text-transform: uppercase;
            color: $color-white;

        }
    }
    &__bio {
        span {
            font-weight: 700;
            display: inline-block;
            margin-right: 5px;
            position: relative;
            line-height: 30px;
            transition: $trs-2;
            &::before{
                content: "";
                display: block;
                height: 2px;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.5;
                background: linear-gradient(to right, rgba(255,255,255, 1) 0%, transparent 60%);
            }
        }
        i{
            color: $color-yellow;
            margin-left: 5px;
            transition: $trs-2;
        }
    }
    &__follow {
        cursor: pointer;
        display: block;
        position: absolute;
        z-index: 1;
        right: 0;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-top: 5px;
        min-width: 70px;
        a {
            border-radius: 20px 0 0 20px;
            padding: 0 10px 0 10px;
            display: block;
            background: #e81f3e;
            color: #fff;
            transition: $trs-2;
        }
    }
}