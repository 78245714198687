@mixin breakpoint ($size) {
    @if ($size == "xlg"){
        @media (min-width: 1920px){
            @content;
        }
    }

    @if ($size == "lg"){
        @media (max-width: 1920px){
            @content;
        }
    }

    @if ($size == "notebook"){
        @media (max-width: 1366px){
            @content;
        }
    }

    @if ($size == "mobile"){
        @media (max-width: 1024px){
            @content;
        }
    }

    @if ($size == "sm"){
        @media (max-width: 786px){
            @content;
        }
    }

    @if ($size == "xs"){
        @media (max-width: 375px){
            @content;
        }
    }

    @if ($size == "xxs"){
        @media (max-width: 320px){
            @content;
        }
    }
}


@mixin breakpoint-between ($min, $max) {
    @media (min-width: $min) and (max-width: $max) {
        @content;
    }
}
