$menu-height: 120px;


header {
    position: relative;
    background: $color-white;
    transition: $trs;
    box-shadow: 0 5px 15px rgba(0,0,0,.07);
    .container {
        @include breakpoint ("notebook"){
            padding: 0;
        }
        margin-bottom: 0px;
    }
    .topbar {
        background: $color-main;
        @include breakpoint("notebook"){
            padding: 0 4%;
        }
    }
    .social {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        li {
            background: rgba(255,255,255,.05);
            border-radius: 3px;
            width: 35px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            margin: 0 5px;
            font-size: $fz-2;
            transition: $trs;
            a {
                color: #687085;
                display: block;
                width: 100%;
                height: 100%;
            }
            &:hover{
                transition: $trs;                
                a {
                    color: $color-white;
                }
                &.facebook {
                    background: #4267b2;
                }
                &.instagram {
                    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
                }
                &.youtube {
                    background: #dc472e;
                }
            }
        }
    }
    .nav-bar {
        width: 100%;
        overflow: hidden;
        height: $menu-height;
        display: grid;
        grid-template-columns: minmax(90px, 170px) auto;
        align-items: center;
        @include breakpoint("mobile"){
            padding: 0 4%;
            max-height: 90px;
            grid-template-columns: minmax(50px, 80px) auto;
        }
        nav {
            position: relative;
            height: 100%;
            ul{
                height: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                @include breakpoint ("notebook"){
                    max-height: 120px;
                }
                @include breakpoint ("mobile"){
                    max-height: 90px;
                    flex-direction: column;
                    justify-content: flex-start;
                    &.menu {
                        align-items: flex-start;
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        z-index: 5000;
                        overflow-y: auto;
                        left: -100%;
                        width: 260px;
                        min-height: 100vh;
                        padding-top: 28px;
                        background: rgba(lighten($color-blue, 10%), 0.9);
                        transition: 0.4s;
                        li {
                            font-weight: 400;
                        }
                    }
                }
                li {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    padding: 0;
                    margin-right: 10px;
                    white-space: nowrap;
                    font-family: $font-trd;
                    font-size: $fz-2;
                    font-weight: 700;
                    transition: $trs;
                    @include breakpoint ("mobile"){
                        margin-right: 0;
                        height: 50px;
                        justify-content: center;
                        width: 100%;
                        a {
                            display: block;
                            width: 100%;
                        }                        
                    }
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $color-grey;
                        height: 4px;
                        width: 0;
                        transition: $trs;
                    }
                    a {
                        color: $color-grey;
                        padding: 0 12px;
                        display: block;
                        position: relative;
                        @include breakpoint ("mobile"){
                            color: $color-white;
                        }
                    }
                    &.patrocinio {
                        a{
                            display: inline-block;
                            background: $color-orange;
                            width: auto;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 5px;
                            padding: 0 25px;
                            text-align: center;
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 800;
                            color: $color-white;
                        }
                        &::before,
                        &::after {
                            content: none;
                        }
                        &:hover {
                            background: none;
                            a{
                                background: darken($color-orange, 10%);
                            }
                        }
                        &.active {
                            background: none;
                            a {
                                background: $color-main;
                            }
                        }
                        @include breakpoint ("mobile"){
                            margin-top: 20px;
                        }
                    }
                    &:hover,
                    &.active {
                        background: $color-main;
                        transition: $trs;
                        @include breakpoint ("mobile"){
                            background: none;
                            &::before {
                                content: none;
                            }
                            a {
                                color: $color-blue-3;
                            }
                        }
                        &::before {
                            width: 100%;
                            transition: $trs;
                            transition-delay: 0.2s;
                        }
                    }
                }
            }
        }
    }

    .bar-button {
        display: none;
        cursor: pointer;
        position: absolute;
        z-index: 5010;
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
        font-size: 24px;
        padding: 10px;
        .fa-times {
            display: none;
        }        
    }

    &.active {
        .nav-bar {
            nav {
                ul {
                    &.menu {
                        left: 0;
                        li {
                            a {
                                @include breakpoint ("mobile"){
                                    padding-left: 30px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1366px){
    .App.scrolled {
        header {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: 2000;
            width: 100%;
            transition: $trs;
        }
        transition: $trs;
    }
}

body.tablet,
body.mobile {
    .bar-button {
        display: block;
    }
    .nav-bar  {
        
    }
    header {
        @include breakpoint ("mobile"){
            display: block;
            &.active {
                .fa-bars {
                    display: none;
                }
                .fa-times {
                    display: block;
                }
            }
        }
    }
}


