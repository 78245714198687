.team-component {
    padding: 80px 15px;
    background: url(../../assets/image/hnewbg.png) no-repeat top center;
    background-attachment: fixed;
    &--header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0;
        background: url(../../assets/image/cheerup.png) no-repeat;
        background-size: cover;
        height: 320px;
        margin-bottom: 20px;
        @include breakpoint("mobile"){
            flex-direction: column;
        }
        .title {
            font-size: 2rem;
            color: $color-white;
            font-weight: 700;
            vertical-align: middle;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            font-family: $font-main-bold;
            @include breakpoint("mobile"){
                font-size: 1.5rem;
                margin-bottom: 10px;
            }
            .text {
                top: 36%;
                position: absolute;
            }
            .members-quantity {
                user-select: none;
                position: absolute;
                right: 0;
                top: auto;
                bottom: 0;
                font-size: $fz;
                font-weight: 300;
                color: #c2c2c2;
                align-self: flex-end;
                margin-right: 10px;
                margin-bottom: 20px;
                @include breakpoint("mobile"){
                    font-size: 0.75rem;
                    margin-bottom: 0;
                }
            }
            i {
                margin-right: 20px;
            }
            &::after {
                content: attr(data-title);
                position: absolute;
                left: 0;
                right: 0;
                font-size: 400%;
                top: 16%;
                font-weight: 300;
                color: rgba(255,255,255,.08);
            }
            &::before {

            }
        }
        .subtitle {
            height: 40px;
            line-height: 40px;
            font-weight: 500;
            width: 100%;
        }
        .all-team {
            position: relative;
            height: 50px;
            width: auto;
            display: inline-block;
            text-align: center;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 40px;
            min-width: 150px;
            height: 40px;
            line-height: 40px;
            padding: 2px 5%;
            letter-spacing: 0.05em;
            transition: $trs;
            background: rgba(255,255,255,.20);
            color: $color-white;
            border-radius: 75px 75px 0 0;
            &:hover {
                // background: $color-red;
                color: $color-yellow;
                transition: $trs;
            }
            @include breakpoint("mobile"){
                font-size: 0.75rem;
            }
        }
    }
    .members-quantity {
        position: absolute;
        top: auto;
        bottom: auto;
        right: 13px;
        font-size: $fz;
        @include breakpoint("mobile"){
            margin-bottom: 0;
            font-size: 0.65rem;
        }
    }
}

.search-member {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    border: 2px solid #e1e1e1;
    background: $color-white;
    border-radius: 3px;
    width: 40%;
    min-width: 320px;
    margin-bottom: 20px;
    @include breakpoint("mobile"){
        width: 94.5%;
        min-width: initial;
        margin-bottom: 30px;
    }

    input {
        height: 43px;
        font-family: 'Roboto Condensed', sans-serif;
        padding: 0 20px;
        font-size: 18px;
        line-height: 39px;
        border: 0px;
        border-radius: 0px;
        color: $color-black;
        font-weight: 500;
    }

    button {
        background: $color-blue-4;
        color: $color-white;
        position: absolute;
        top: 0;
        right: 0;
        height: 43px;
        border: 0px;
        padding: 0 20px;
        font-size: 16px;
        text-transform: uppercase;
        cursor: pointer;
    }
}

.team {
    &__container {
        .no-info {
            display: block;
            letter-spacing: 0.15em;
            text-align: center;
            font-size: 18px;
        }
        .active-members,
        .inactive-members,
        .in-memory {
            .subtitle {
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 100%;
                height: 50px;
                line-height: 50px;
                padding-left: 25px;
                font-size: $fz-5;
                text-transform: uppercase;
                color: $color-white;
                border-radius: 3px;
                margin-bottom: 20px;
                @include breakpoint("mobile"){
                    font-size: 0.75rem;
                    padding-left: 10px;
                }
            }
        }
        .members-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 60px;
        }
        .active-members {
            .subtitle{
                background: darken(#1a83d5, 10%);
            }
        }
        .inactive-members {
            .subtitle{
                background: darken($color-red, 10%);
            }
        }
        .in-memory {
            .subtitle{
                background: darken($color-grey, 50%);
            }
        }

        .anchor {
            margin-right: 20%;
            font-size: $fz;
            color: $color-white;
            text-transform: lowercase;
            padding-top: 3px;
            opacity: 0.9;
            min-width: 120px;
            i {
                margin-left: 10px;
            }
            &:hover {
                color: darken($color-white, 20%);
            }
            &__offset{
                display: block;
                position: relative;
                top: -350px;
                visibility: hidden;
            }
            @include breakpoint("mobile"){
                font-size: 0.75rem;
                padding-top: 0;
                i {
                    margin-left: 5px;
                }
            }
        }
        @include breakpoint("sm"){
            flex-direction: column;
        }
    }
    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 45px;
        min-height: 350px;
        max-width: 280px;
        width: 49%;
        border-radius: 3px;
        padding-top: 0;
        overflow: hidden;

        &:hover {
            .team {
                &__nickname {
                    background: $color-red;
                    transition: $trs-2;
                    &:before {
                        border-bottom: 10px solid $color-red;
                        transition: $trs-2;
                    }  
                }
                &__ribbon {
                    top: 0;
                    opacity: 1;
                    transition: $trs 0.1s;
                    a {
                        transition: $trs;
                    }
                }
                &__follow {
                    right: 0;
                    opacity: 1;
                    transition: $trs 0.1s;
                    a {
                        transition: $trs;
                    }
                }
                &__nickname {
                    transition: $trs-2;
                    bottom: 33%;
                }
                &__godfather {
                    transition: $trs-2;
                    bottom: 33%;
                }
                &__name {
                    transition: $trs-2;
                    bottom: 24%;
                }
                &__position {
                    transition: $trs-2;
                    bottom: 20%;
                }
                &__career {
                    transition: $trs-2;
                    bottom: 3%;
                    visibility: visible;
                }
                &__image {
                    img {
                        transition: $trs;
                    }
                    .no-image {
                        background-color: lighten($color-black, 20%);
                    }
                }
                &__info {
                    &::before {
                        opacity: 0.6;
                        bottom: 0;
                        transition: $trs;
                    }
                }
            }
        }
        @include breakpoint("sm"){
            flex-direction: column;
            max-width: initial;
            width: 100%;
            margin-bottom: 40px;
            .team {
                &__nickname {
                    // background: $color-red;
                    transition: $trs-2;
                    &:before {
                        // border-bottom: 10px solid $color-red;
                        transition: $trs-2;
                    }  
                }
                &__ribbon {
                    top: 0;
                    opacity: 1;
                    a {
                    }
                }
                &__follow {
                    right: 0;
                    opacity: 1;
                    a {
                    }
                }
                &__nickname {
                    bottom: 34%;
                }
                &__godfather {
                    bottom: 34%;
                }
                &__name {
                    bottom: 24%;
                }
                &__position {
                    bottom: 20%;
                }
                &__career {
                    bottom: 3%;
                    visibility: visible;
                }                
                &__info {
                    &::before {
                        opacity: 0.4;
                        height: 240px;
                        transition: $trs;
                    }
                }
            }
        }
    }
    &__image,
    &__info {
        position: absolute;
        height: 100%;
    }
    &__image {
        position: relative;
        width: 100%;
        min-height: 460px;
        background: #333333;
        border-radius: 3px;
        padding-top: 43px;
        @include breakpoint("mobile"){
            max-width: initial;
            min-height: 450px;
            width: 100%;
        }
        img {
            margin-top: 0;
            // position: absolute;
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 0;
        }    
        .no-image {
            position: absolute;
            display: block;
            width: 100%;
            height: calc(100% - 4px);
            text-align: center;
            font-size: 0;
            background-image: url(../../assets/image/soccer-player.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 0 20%;
            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
            }
            &::before {
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: raidail-gradient($color-blue, $color-black);
                border-radius: 3px;
            }
            &::after {
                content: attr(alt);
                z-index: 2;
                top: 40%;
                right: 0;
                bottom: auto;
                left: 0;
                height: auto;
                color: lighten($color-black, 60%);
                font-size: 14px;
                font-weight: 500;
            }
        }    
    }
    &__info {        
        padding: 0 0 4% 0;
        width: 100%;
        color: $color-white;
        z-index: 40;
        &::before {
            content: "";
            width: 100%;
            background: linear-gradient(transparent, #000 3%);
            opacity: 0;
            position: absolute;
            height: 200px;
            bottom: -100px;
            z-index: 0;
            transition: $trs-2;
        }
        @include breakpoint("mobile"){
            padding: 10% 0 4% 0;
            width: 100%;
        }
    }
    &__nickname {
        display: none;
        position: absolute;
        top: -10px;
        right: 3%;
        display: block;
        width: auto;
        min-width: 45px;
        height: 35px;
        line-height: 35px;
        background: $color-blue-5;
        text-align: center;
        padding: 0 10px;
        border-radius: 0 0 3px 3px;
        font-size: 1rem;
        font-weight: 400;
        font-family: $font-main-bold;
        transition: $trs-2;
        &::before {
            content: "";
            position: absolute;
            left: -11px;
            top: 0;
            width: 0;
            border-bottom: 10px solid darken($color-blue-5, 10%);
            border-left: 11px solid transparent;
            transition: $trs-2;
        }
    }
    &__ribbon {
        position: absolute;
        z-index: 1;
        display: block;
        margin: 10px 15px;
        transition: $trs;
        opacity: 1;
        top: 0;
        

        i, svg {
            font-size: 1.5rem;
        }
    }
    &__nickname,
    &__godfather,
    &__name {
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        min-height: 30px;
        line-height: 1.4;
        transition: $trs-2;
    }
    &__nickname {
        font-size: $fz-4;
        height: 55px;
        line-height: 63px;
        @include breakpoint("mobile"){
            font-size: $fz-6;
        }
    }
    &__godfather {
        font-size: $fz-4;
        bottom: 15%;
        @include breakpoint("mobile"){
            font-size: $fz-6;
        }
    }
    &__name {
        font-size: 1rem;
        bottom: 6%;
    }
    &__position {
        color: #cccccc;
        text-transform: uppercase;
        font-size: $fz;
        font-weight: 400;
        margin-bottom: 15px;
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        transition: $trs-2;
        bottom: 2%;
    }
    &__words {
        color: $color-grey;
        font-size: $fz-2;
        height: 40px;
        letter-spacing: 0.05em;
        line-height: 1.4;
    }
    &__career {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto 15px;
        position: absolute;
        width: 90%;
        left: 0;
        right: 0;
        transition: $trs-2;
        bottom: -60px;
        visibility: hidden;
        
        li {
            // line-height: 30px;
            color: #ccc;
            font-size: 0.75rem;
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            padding: 0 10px;
            font-weight: 300;
            &:first-child{
                border-left: none;
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            p {
                &:first-child{
                    font-weight: 500;
                    margin-bottom: 7px;
                }
            }
        }
    }
    &__engagement {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 70px;
        position: relative;
        top: 30%;
        z-index: 0;
        @include breakpoint("mobile"){
            margin-top: 40px;
            flex-direction: column;
        }
        a, span {
            text-transform: uppercase;
            color: $color-white;

        }
    }
    &__bio {
        display: none;
        top: 30%;
        right: 15px;
        position: absolute;
        a {
            font-weight: 500;
            display: inline-block;
            margin-right: 5px;
            position: relative;
            line-height: 30px;
            transition: $trs;
            &::before{
                content: "";
                display: block;
                height: 3px;
                position: abssolute;
                top: 0;
                left: 0;
                opacity: 0.5;
                background: linear-gradient(to right, rgba(255,255,255, 1) 0%, rgba(23,30,54,0.5) 100%);
            }
        }
        i{
            color: $color-white;
            transition: $trs;
        }
    }
    &__follow {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 50%;
        z-index: 1;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-top: 5px;
        min-width: 60px;
        transition: $trs;
        opacity: 0;
        right: -100%;
        a {
            border-radius: 20px 0 0  20px;
            padding: 0 10px 0 10px;
            display: block;
            background: $color-red;
            color: #fff;
            transition: $trs;
        }
    }
}