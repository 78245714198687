.nextgame {
    /* max-width: 400px; */
    margin: 0 auto 40px;
    background: url(../../assets/image/match-detailsbg.png) no-repeat;
    background-size: cover;
    padding: 60px 0;
    position: relative;
    margin-bottom: 0;
}

.next-game-card{
    font-family: $font-main-bold;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;
    h5{
        background-color: $color-orange;
        color: $color-white;
        line-height: 51px;
        text-align: center;
        margin: 0;
        font-size: 18px;
        font-weight: 400;
    }
    .nmw-wrap{
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $color-grey-2;
        
        .match-teams-vs{
            margin: 0;
            padding: 4vh 20px;
            list-style: none;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;            

            .team-logo{
                width: 40%;
                display:flex;
                flex-direction: column;
                align-items: center;
                position: relative;

                &:after{
                    content: '';
                    width: 10px;
                    height: 10px;
                    opacity: 0.5;
                    left: 50%;
                    border-radius: 100%;
                    display: block;
                    position: absolute;
                    top: 50%;
                    background-color: transparent;
                    box-shadow: 1px 80px 8px 7px #777777, 1px 80px 10px 15px #b7b7b7;
                    transform-style: preserve-3d;
                    transform: translate(-50%, 0) scaleY(0.1);
                }
                img{
                    position: relative;
                    z-index: 5;
                    vertical-align: middle;
                    width: 60px;
                    height: 60px;
                    border-radius: 100%;
                    transition: 0.2s all;
                }
                strong{
                    // font-size: 24px;
                    font-size: calc(calc(9px + 0.3vw) / 0.5);
                    color: $color-black;
                    font-weight: 400;
                    text-align: center;
                    margin: 40px 0 0;
                    position: relative;
                }
            }               
            .mvs{
                width: 20%;
                display:flex;
                flex-direction: column;
                align-items: center;
                font-weight: bold;
                font-size: 1rem;

                strong{
                    color: $color-red;
                    font: { 
                        size: $fz-2;
                        family: $font-trd;
                        weight: 700;
                    }
                    background-color: $color-grey-2;
                    border: 1px solid $color-grey-2;
                    line-height: 30px;
                    padding: 2px 18px;
                    border-radius: 17px;
                    margin: calc(-1vh / 0.25) 0 0;
                }
            }
        }
        .nmw-txt{
            text-align: center;
            margin: 0 auto;
            padding: 0 0 4vh;
            list-style: none;
            max-width: 70%;
            @include breakpoint("mobile"){
                max-width: 100%;
            }

            li {
                font-family: $font-trd;
                font-size: $fz;
                line-height: calc(calc(10px + 0.5vh) / 0.5);
                // line-height: calc(1vh / 0.25);
            }

            &__description {
                font-family: $font-main-bold;
                color: $color-black;
                font-size: $fz-2;
                text-transform: uppercase;
                font-weight: 700;
            }
            &__fullDate,
            &__fullHour,
            &__location {
                font-family: $font-trd;
            }
            &__status {
                color: $color-red; 
                text-transform: uppercase;
                font-weight: 700;
                text-align: center;
                padding: 0px 0 40px;
            }
            &__fullDate {
                .default-date {
                    color: $color-black;  
                    margin: 0;
                    i {
                        display: none;
                    }
                }
            }
            &__location {
                color: $color-red;
            }
            &.is-over {
                li {
                    text-decoration: line-through;
                }
            }
        }
        .defaultCountdown{
            width: 100%;
            height: auto;
            line-height:35px;            
            display: flex;
            align-items: center;
            border: {
                style: solid;
                color: $color-grey-2;
                width: 1px 0 0 0;
            }

        }
        .countdown {
            &-row{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-around;

                > span {
                    border-right: 1px solid $color-grey-2;
                    &:last-child{
                        border-right: none;
                    }
                }

            }
            &-action {
                display: none;
                text-align: center;
                padding: 24px 0;
                border: {
                    style: solid;
                    color: $color-grey-2;
                    width: 1px 0 0 0;
                }
            }
            &-section {
                width: calc(50%);
                display: flex;
                flex-direction: column;
                padding: calc(3px + 0.3vw) 0;
                font-size: 24px;
                font-weight: 300;
                color: $color-orange;
                text-align: center;
            }
            &-amount {
                color: #666666;
                font-family: $font-main-bold;
                font-size: $fz-6;
                font-weight: 500;
            }
            &-period {
                font-size: 12px;
                text-transform: uppercase;
                color: #999;
            }
        }
        &__top{
            background-color: $color-white;
        }
        &__bottom{
            background-color: $color-white;
        }
    }
}