@import './mixins';

$font-main: 'Roboto', 'Montserrat', sans-serif;
$font-main-bold: 'Roboto Condensed', 'Montserrat', sans-serif;
$font-sec: 'Poppins', $font-main;
$font-trd: 'Open Sasns', $font-main;
$color-main: #171e36;
$color-blue: #20273f;
$color-blue-2: #0c1121;
$color-blue-3: #8dc2fa;
$color-blue-4: #232940;
$color-blue-5: #38a1f3;
// $color-orange: #ff850b;
$color-orange: #ff550b;
$color-red: #e81f3e;
$color-yellow: #ffcb05;
$color-black: #000;
$color-grey:#7c859a;
$color-grey-2:#e1e1e1;
$color-grey-3:#ccc;
$color-white: #fff;
$color-error:  #dd5065;
$color-alert:  #dd8850;
$color-success:  #3bcc1e;
$color-offwhite:#fcf3cc;
$color-background: #f7f7f7;
$fz: 0.875rem; //14px
$fz-2: 1rem; //16px
$fz-3: 1.125rem; //18px
$fz-4: 1.25rem; //20px
$fz-5: 1.37rem; //22px
$fz-6: 1.56rem; //25px
$fz-mb: calc(10px + 2vmin);
$trs: 0.4s;
$trs-2: 0.6s;
$trs-3: 0.8s;