.aniversary-component {
    background: url(../../assets/image/innerpagebg.png) no-repeat top center;
    background-size: cover;
    padding: 80px 15px;
    &--header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        padding: 0;
        background-image: url(../../assets/image/footbalfield.png);
        background-size: cover;
        background-repeat: no-repeat;
        height: 200px;
        margin-bottom: 20px;
        @include breakpoint("mobile"){
            flex-direction: column;
        }
        .title {
            font-size: 2rem;
            color: $color-white;
            font-weight: 700;
            vertical-align: middle;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            font-family: $font-main-bold;
            @include breakpoint("mobile"){
                font-size: 1.5rem;
                margin-bottom: 20px;
            }
            i {
                margin-right: 20px;
            }
            &::after {
                // content: attr(data-title);
                // position: absolute;
                // left: 0;
                // right: 0;
                // font-size: 320%;
                // top: 10%;
                // font-weight: 300;
                // color: rgba(255,255,255,.08);
            }
            &::before {
                content: "\f1fd";
                @include fontAwesome;
                position: absolute;
                left: 18%;
                font-size: 350%;
                top: 15%;
                color: rgba(255, 255, 255, 0.1);
                @include breakpoint("mobile"){
                }
            }
        }
        .all-members {
            position: relative;
            height: 50px;
            width: auto;
            display: inline-block;
            text-align: center;
            font-weight: 300;
            text-transform: uppercase;
            font-size: 1rem;
            line-height: 40px;
            min-width: 150px;
            height: 40px;
            line-height: 40px;
            padding: 2px 5%;
            letter-spacing: 0.05em;
            transition: $trs;
            background: rgba(255,255,255,.20);
            color: $color-white;
            border-radius: 75px 75px 0 0;
            &:hover {
                // background: $color-red;
                color: $color-yellow;
                transition: $trs;
            }
            @include breakpoint("mobile"){
                font-size: 0.75rem;
            }
        }
    }
}

.page-aniversariante {
    .aniversary-component--header {
        display: none;
    }
    main {
        padding-top: 7vh;
    }
}

.aniversaryMember {
    padding-top: 0;
    margin-bottom: 0;
}

.aniversary-member {
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        @include breakpoint("sm"){
            flex-direction: column;
        }
    }
    &__card {
        position: relative;
        display: flex;
        flex-direction: row;
        margin-bottom: 45px;
        min-height: 350px;
        max-width: 270px;
        width: 49%;
        border-radius: 3px;
        padding-top: 10px;
        overflow: hidden;

        &:hover {
            .aniversary-member {
                &__date {
                    background: #e81f3e;
                    transition: $trs-2;
                    &:before {
                        border-bottom: 10px solid #e81f3e;
                        transition: $trs-2;
                    }  
                }
                &__ribbon {
                    top: 0;
                    opacity: 1;
                    transition: $trs 0.1s;
                    a {
                        transition: $trs;
                    }
                }
                &__follow {
                    display: none;
                    left: 0;
                    opacity: 1;
                    transition: $trs 0.1s;
                    a {
                        transition: $trs;
                    }
                }
                &__nickname {
                    transition: $trs-2;
                    bottom: 33%;
                }
                &__name {
                    transition: $trs-2;
                    bottom: 24%;
                }
                &__position {
                    transition: $trs-2;
                    bottom: 20%;
                }
                &__career {
                    transition: $trs-2;
                    bottom: 3%;
                    visibility: visible;
                }
                &__image {
                    img {
                        transition: $trs;
                    }
                    .no-image {
                        background-color: lighten($color-black, 20%);
                    }
                }
                &__info {
                    &::before {
                        opacity: 0.4;
                        bottom: 0;
                        transition: $trs;
                    }
                }
            }
        }
        @include breakpoint("sm"){
            flex-direction: column;
            max-width: initial;
            width: 100%;
            margin-bottom: 40px;
            .aniversary-member {
                &__date {
                    // background: #e81f3e;
                    transition: $trs-2;
                    &:before {
                        // border-bottom: 10px solid #e81f3e;
                        transition: $trs-2;
                    }  
                }
                &__ribbon {
                    top: 0;
                    opacity: 1;
                    a {
                    }
                }
                &__follow {
                    left: 0;
                    opacity: 1;
                    a {
                    }
                }
                &__nickname {
                    bottom: 34%;
                }
                &__name {
                    bottom: 24%;
                }
                &__position {
                    bottom: 20%;
                }
                &__career {
                    bottom: 3%;
                    visibility: visible;
                }
                &__info {
                    &::before {
                        opacity: 0.4;
                        height: 240px;
                        transition: $trs;
                    }
                    @include breakpoint("mobile"){
                        &::before {
                            height: 430px;
                        }
                    }
                }
            }
        }
    }
    &__image,
    &__info {
        position: absolute;
        height: 100%;
    }
    &__image {
        position: relative;
        width: 100%;
        min-height: 441px;
        background: lighten($color-black, 20%);
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        align-items: center;
        @include breakpoint("mobile"){
            max-width: initial;
            min-height: 541px;
            padding-top: 40px;
            width: 100%;
            display: flex;
            text-align: center;
            align-items: center;
            justify-content: center;
        }
        img {
            position: absolute;
            display: block;
            width: 100%;
            height: auto;
            text-align: center;
            font-size: 0;
            @include breakpoint("mobile"){
                position: relative;
            }
        }    
        .no-image {
            position: absolute;
            display: block;
            width: 100%;
            height: calc(100% - 4px);
            text-align: center;
            font-size: 0;
            background-image: url(../../assets/image/soccer-player.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 0 20%;
            &::before,
            &::after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
            }
            &::before {
                display: block;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                // background: radial-gradient($color-blue, $color-black);
                border-radius: 3px;
            }
            &::after {
                content: attr(alt);
                z-index: 2;
                top: 50%;
                right: 0;
                bottom: auto;
                left: 0;
                height: auto;
                color: lighten($color-black, 60%);
                font-size: 14px;
                font-weight: 500;
            }
        }    
    }
    &__info {        
        padding: 6% 0 4% 0;
        width: 100%;
        color: $color-white;
        z-index: 40;
        &::before {
            content: "";
            width: 100%;
            background: linear-gradient(transparent, #000 3%);
            opacity: 0;
            position: absolute;
            height: 230px;
            bottom: -100px;
            z-index: 0;
            transition: $trs-2;
        }
        @include breakpoint("mobile"){
            padding: 10% 0 4% 0;
            width: 100%;
            &::before{
                height: 430px;
            }
        }
    }
    &__date {
        position: absolute;
        top: -10px;
        right: 3%;
        display: block;
        width: auto;
        min-width: 45px;
        height: 35px;
        line-height: 35px;
        background: #38a1f3;
        text-align: center;
        padding: 0 10px;
        border-radius: 0 0 3px 3px;
        font-size: 1rem;
        font-weight: 400;
        font-family: $font-main-bold;
        transition: $trs-2;
        &::before {
            content: "";
            position: absolute;
            left: -11px;
            top: 0;
            width: 0;
            border-bottom: 10px solid #1a83d5;
            border-left: 11px solid transparent;
            transition: $trs-2;
        }
    }
    &__ribbon {
        position: absolute;
        z-index: 10;
        left: 5%;
        display: block;
        width: 30px;
        height: 40px;
        background: linear-gradient($color-orange, $color-yellow);
        text-align: center;
        transition: $trs;
        opacity: 1;
        top: 0;
        svg {
            font-size: 1.5rem;
            position: relative;
            top: 10px;
        }
    }
    &__nickname,
    &__name {
        font-weight: 400;
        margin-bottom: 20px;
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        min-height: 40px;
        line-height: 1.4;
        transition: $trs-2;
    }
    &__nickname {
        font-size: $fz-4;
        bottom: 15%;
        @include breakpoint("mobile"){
            font-size: $fz-6;
        }
    }
    &__name {
        font-size: 0.875rem;
        bottom: 6%;
    }
    &__position {
        color: #cccccc;
        text-transform: uppercase;
        font-size: $fz;
        font-weight: 400;
        margin-bottom: 15px;
        text-align: center;
        position: absolute;
        right: 0;
        left: 0;
        transition: $trs-2;
        bottom: 4%;
    }
    &__words {
        color: $color-grey;
        font-size: $fz-2;
        height: 40px;
        letter-spacing: 0.05em;
        line-height: 1.4;
    }
    &__career {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0 auto 15px;
        position: absolute;
        width: 90%;
        left: 0;
        right: 0;
        transition: $trs-2;
        bottom: -60px;
        visibility: hidden;
        
        li {
            line-height: 30px;
            color: #ccc;
            font-size: $fz-2;
            border-left: 1px solid rgba(255, 255, 255, 0.3);
            padding: 0 7px;
            font-weight: 300;
            @include breakpoint("mobile"){
                padding: 0 15px;
            }
            &:first-child{
                border-left: none;
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            p {
                &:first-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &__engagement {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 70px;
        position: relative;
        z-index: 0;
        @include breakpoint("mobile"){
            margin-top: 40px;
            flex-direction: column;
        }
        a, span {
            text-transform: uppercase;
            color: $color-white;

        }
    }
    &__bio {
        display: none;
        top: 30%;
        right: 15px;
        position: absolute;
        a {
            font-weight: 500;
            display: inline-block;
            margin-right: 5px;
            position: relative;
            line-height: 30px;
            transition: $trs;
            &::before{
                content: "";
                display: block;
                height: 3px;
                position: abssolute;
                top: 0;
                left: 0;
                opacity: 0.5;
                background: linear-gradient(to right, rgba(255,255,255, 1) 0%, rgba(23,30,54,0.5) 100%);
            }
        }
        i{
            color: $color-white;
            transition: $trs;
        }
    }
    &__follow {
        cursor: pointer;
        display: block;
        position: absolute;
        top: 10px;
        z-index: 1;
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-top: 5px;
        min-width: 60px;
        transition: $trs;
        opacity: 0;
        left: -100%;
        a {
            border-radius: 0 20px 20px 0;
            padding: 0 10px 0 10px;
            display: block;
            background: #e81f3e;
            color: #fff;
            transition: $trs;
        }
    }
}