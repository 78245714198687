footer {
    background: $color-main;
    color: $color-grey;
    position: relative;
    z-index: 10;
    ul, li, a {
        color: $color-grey;
    }

    a {
        font-family: $font-main-bold;
        &:hover {
            color: $color-white;
        }
    }
    i {
        color: $color-orange;
        display: inline-block;
        margin-right: 10px;
    }
    .container {
        margin-bottom: 0;
        @include breakpoint("xxs"){
            overflow-x: hidden;
        }
    }
    .logo {
        pointer-events: none;
        user-select: none;
        img {
            max-width: 80%;
        }
        @include breakpoint("notebook"){
            max-width: 90px;
        }
        &:hover {
            img{
                transform: none;
                transition: $trs;
            }
        }
    }
    
    .wrap {
        display: grid;
        // grid-template: auto / repeat(4, 23%);´
        grid-template: auto/40% 1fr;
        gap: 1vw 2%;
        justify-content: space-between;
        padding-top: 40px;
        @include breakpoint("notebook"){
            grid-template: auto / repeat(4, auto);
            gap: 4vh 2%;
        }
        @include breakpoint("mobile"){
            padding: 10px 0;
            grid-template: auto / auto;
        }
    }

    .group {
        display: grid;
        grid-template-rows: 100px repeat(10, auto);
        > li {
            line-height: 2;
            min-height: 40px;
            @include breakpoint("mobile"){
                margin-bottom: 10px;
                min-height: 30px;
            }
            &:first-child {
                align-self: flex-start;
            }
        }
        &:last-child {
            margin-top: 50px;
            grid-column-end: span 4;
            grid-template-rows: minmax(auto, 60px);
            grid-template-columns: 45% repeat(6, auto);
            border-top: 1px solid rgba($color-grey, 0.2);
            
            @include breakpoint("mobile"){
                margin-top: unset;
                grid-template-columns: minmax(160px, auto);
                grid-column-end: auto;
                li {
                    grid-column-end: span 2;
                    margin-right: 15px;
                    min-width: 150px;
                    &:first-child {
                        grid-column-end: span 5;
                        padding-bottom: 30px;
                    }
                }
            }

            h2 { 
                /* Powered By */
                font-size: $fz;
                a {
                    font-weight: 400;
                    font-size: 12px;                    
                    margin-left: 5px;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    color: $color-orange;
                    &:hover {
                        color: $color-white;
                    }
                }
                &::before,
                &::after {
                    content: none;
                }
            }

            > li {
                &:first-child {
                    margin: 0;
                    grid-template-rows: auto;
                    align-self: center;
                    @include breakpoint("mobile"){
                        margin: 40px 0 10px;
                        grid-template-rows: auto;
                    }
                }
            }           
        }     

        &-2,
        &-3,
        &-4 {            
            .footer {
                &--title {
                    position: relative;
                    font-size: 1.5rem;
                    color: $color-grey;
                    white-space: nowrap;
                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        background: $color-orange;
                        display: block;
                        left: 0;
                        height: 2px;
                    }
                    &::before {
                        bottom: -10px;
                        width: 40px;
                    }            
                    &::after {
                        bottom: -15px;
                        width: 30px;
                    }
                }
                &--newsletter {
                    order: 1;
                }
            }
        }

        &-2 {
            display: none;
            grid-template-columns: minmax(130px, 50%) 50%;

            @include breakpoint("mobile"){
                margin: 0;
            }
            li {
                &:first-child {
                    grid-area: 1 / 1 / 1 / 3;
                }
               a {
                    &:before {
                        content: "\f101";
                        @include fontAwesome;
                        display: inline-block;
                        font-size: 14px;
                        margin-right: 3px;
                    }
               }
            }     
        }
        &-5 {
            align-items: center;
        }
    }

    .list {
        // height: 35px;
        // line-height: 1.4rem;
    }


    
    .newsletter {
        &__box {
            &:first-child {
                padding-top: 10px;
            }
            &--submit {
               
            }
        }
        &--wrap {

        }
    }

    .instagram {
        max-width: 786px;
        &-grid {
            display: grid;
            grid-template: repeat(3, auto) / repeat(3, 1fr);
            gap: 10px 10px;
        }
        &-item {            
            flex: unset;
            max-width: initial;
            align-items: center;
            overflow: hidden;
            &:first-child {
                grid-area: 1 / 1 / 3 / 3;

            }
            img {
                border-radius: 3px;
            }
        }
    }
}