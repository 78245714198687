.newsletter {
  &__box {
    margin-bottom: 10px;
    &--submit {
      position: relative;
      display: flex;
      justify-content: space-between;
      button {
        margin: 0 0 0 auto;
        @include breakpoint("mobile") {
          width: 100%;
          margin: 0 auto;
        }
      }
      select,
      input,
      textarea {
        color: $color-grey;
        &.valid {
          color: $color-success;
        }
        &.error {
          color: $color-error;
        }
        &.success {
          color: $color-success;
        }
        &.alert {
          color: $color-alert;
        }

        &:focus {
          outline: none;
        }
        &:focus,
        &.isTyping {
          + .contact__form--label {
            transform: translate(-0.75rem, calc(-50% - 1.85rem));
            font-size: 1rem;
          }
        }
        &:disabled {
          color: $color-grey;
        }

        &.error {
          color: $color-error;
          ~ .alert {
            &--error {
              display: block;
            }
          }
          ~ .fa-check {
            display: none;
          }
          + label {
            color: $color-error;
          }
        }
        &.valid {
          color: #fff;
          border-bottom: 1px solid #fff;
          font-weight: 500;
          ~ .fa-check {
            // color: #fff;
            color: $color-success;
            display: block;
          }
        }
      }
      strong {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
        margin-right: 20px;
      }
    }
  }
  &--wrap {
    @include breakpoint("xs") {
      width: 96vw;
    }
  }
  .form-control {
    &__status {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}
