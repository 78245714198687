
@mixin title {
    font: { 
        family: $font-main-bold;
        size: 28px;
        weight: 700;
    }
    margin: 0 0 17px;
}

.page-contato {
    background: url(../../assets/image/innerpagebg.png) no-repeat top center;
    background-attachment: fixed;
    overflow-x: hidden;
}

.contact{
    margin: 0 auto 0;
    font-family: $font-main-bold;
    padding: 0;
    max-width: 95%;
    // box-shadow: 0 5px 15px rgba(0,0,0,.10);
    @include breakpoint("mobile"){
        max-width: 100%;
    }
    form {
        &.disabled {
            input, textarea, button {
                opacity: 0.7;
                pointer-events: none;
            }
        }
    }
    input,
    textarea {
        border-radius: 3px;
        border: 3px solid #e1e1e1;
        background-color: $color-white;
        line-height: 47;
        font-family: $font-main-bold;
        font-size: $fz-3;
        margin: 0 0 10px;
        padding: 20px;
        min-height: 53px;
        transition: 0;
        &:focus,
        &:active {
            background-color: transparent;
            border-color: $color-orange;
            transition: 0;
        }
    }
    textarea {
        height: calc(100px + 10vw);
        vertical-align: text-top;
        line-height: 24px;
    }
    button {
        width: 100%;
        height: 52px;
        font-size: $fz-2;
        letter-spacing: 2px;
        &:hover {
            background-color: $color-blue;
        }
        &:after {
            content: none;
        }
        &:disabled {
            pointer-events: none;
        }
    }

    .alert {
        display: none;
        padding: 20px 0 0;    
        font-size: calc(10px + 1vh);
        font-family: $font-main-bold;;
        font-weight: 600;
        text-align: right;
        &--error,
        &--success,
        &--warning {
            display: block;
        }

        &--error {
            color: darken($color-error, 12%);
        }
        &--success {
            color: darken($color-success, 12%);
        }
    }

    select,
    input,
    textarea {
        color: $color-grey !important;
      &.valid {
        border-color: $color-success;
      }
      &.error {
        border-color: $color-error;
      }
      &.success {
        border-color: $color-success;
      }
      &.alert {
        border-color: $color-alert;
      }

      &:focus {
        outline: none;
      }

      &:disabled {
        color: $color-grey;
      }

      &.error {
        border-color: $color-error;
        ~ .alert {
          &--error {
            display: block;
          }
        }
      }
      &.valid {
        border-color: $color-success;
        font-weight: 500;
      }
    }


    .half-col {
        width: 49%;
        @include breakpoint("mobile"){
            width: 100%;
        }
    }
    .full-col {
        width: 100%;
    }


    &-title{
        @include title;
    }
    &-text{
        font-size: 16px;
        font-family: $font-main;
        line-height: 20px;
        margin-top: 40px;
        padding: 40px 40px 20px;
        margin: 40px 20px 0;
        background-color: $color-white;
        p{
            color: #777777;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 20px;

            strong{
                font-weight: 500;
            }
        }
        ul{
            padding: 0 30px 40px;
            
            li{
                line-height: 30px;
                font-weight: 500;
            }
        }
    }
    .cards-row{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin: 30px 0;
        box-sizing: border-box;
        justify-content: space-between;
        padding: 20px;

        .card-contact{
            display: flex;
            align-items: center;
            width: 32%;
            min-width: 270px;
            position: relative;
            cursor: default;
            transition: all ease-in-out 0.8s;
            border: 1px solid $color-grey-2;
            border-radius: 3px;
            background-color: $color-white;
            @include breakpoint("mobile"){
                width: 100%;
                margin-bottom: 40px;
            }

            &:hover{
                background: $color-grey-2;
                box-shadow: 0 5px 15px rgba(0,0,0,.10)

            }
            .contact-box{
                text-align: center;
                padding: 50px;

                h5{
                    font-size: 20px;
                    color: $color-orange;
                    font-weight: 500;
                    margin: 0 auto 10px;
                }

                p{
                    margin: 0;
                    font-size: 16px;
                    color: $color-grey;
                    line-height: 26px;
                    a {
                        color: $color-grey;
                    }
                }

                [class*=-ico]{
                    filter: invert(48%) sepia(99%) saturate(848%) hue-rotate(353deg) brightness(102%) contrast(103%);
                }

                .address-ico{
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    margin: 0  auto 20px;
                    background: url('../../assets/image/loc-icon.png') center no-repeat;
                }

                .contact-ico{
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    margin: 0  auto 20px;
                    background: url('../../assets/image/phone-icon.png') center no-repeat;
                }

                .mail-ico{
                    width: 40px;
                    height: 40px;
                    text-align: center;
                    margin: 0  auto 20px;
                    background: url('../../assets/image/mail-icon.png') center no-repeat;
                }

            }
        }
    }
    .google-map{
        padding: 10px 20px;
        position: relative;
        min-height: calc(300px + 10vw);
        max-height: 700px;
        .map{
            width: 100vw;
            height: 100%;
            max-width: 1920px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            padding: 0;
            margin: 0 calc(50% - 50vw);

            iframe{
                width: 100%;
                height: 100%;
                border: 0;
                position: relative;
            }
        }
    }
    &-form {
        max-width: 786px;
        margin: 40px auto 20px;
        padding: 20px;
        .form-row {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
        }
        h2 {
            @include title;
        }
    }
}