body {
    scroll-behavior: smooth;
    font-family: $font-main;
    font-size: $fz;
    // background-color: $color-background;
}

html, body {
    box-sizing: border-box;
}

a {
    color: #000;
    &[href=""]{
        pointer-events: none;
    }
}

::-webkit-scrollbar {
    height: 6px;
    width: 12px;
    background: lighten($color-blue, 5%);
}

::-webkit-scrollbar-thumb {
    background: $color-grey;
}

.no-overflow {
    overflow: hidden;
}


form {
    &:disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

input, textarea, select, button {
    font-size: 14px;
    height: 40px;
    line-height: 38px;
}

input, textarea, select {
    background: none;
    border: 1px solid #555555;
    border-radius: 3px;
    padding: .375rem .75rem;
    width: 100%;
    transition: 0.8s;
    color: $color-grey;
    &:focus, &:active {
        border: 1px solid $color-orange;
        outline: $color-orange;
        transition: 0.8s;
        color: darken($color-grey, 30%);
    }
}

button {
    cursor: pointer;
    text-align: left;
    border: none;
    outline: none;
    background: transparent;
}


.button {
    border-style: solid;
    border-width: 1px;
    border-radius: 3px;
    font-weight: 700;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    width: auto;
    // min-width: 80px;
    padding: 0 15px;
    cursor: pointer;
    overflow: hidden;
    position: relative;

    span {
        position: relative;
        z-index: 5;
        transition: all ease-in-out $trs;
    }

    &::after {
        content: "";
        text-align: right;
        position: absolute;
        left: 0;
        top: 0;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        opacity: 0;
        transition: all ease-in-out $trs;
    }
    &:hover {
        &::after {
            opacity: 0.5;
            width: 100%;
            transition: all ease-in-out $trs;
        }
    }

    &:disabled{
        cursor: auto;
        pointer-events: none;
    }
}

.button-primary {
    background: $color-orange;
    color: $color-white;
    border: none;
    &::after {
        background: rgba(0,0,0,.7);
    }

    &:hover {
        border: none;
        &::after {}
    }
    &:disabled{
        background: lighten($color-orange, 20%);
    }
}

.button-secundary {
    border-color: $color-orange;
    color: $color-orange;
    background: transparent;
    &::after {
        background: $color-orange;
    }
    &:hover {
        color: $color-white;
        &::after {
            background: $color-orange;
        }
    }
    &:disabled{
        opacity: 0.5;
        filter: grayscale(0.5);
    }
}


textarea {
    height: auto;
}


.container {
    // max-width: 1140px;
    @include page-size;
}


.container-bg {
    // max-width: 1140px;
    // @include page-size-bg;
    @include page-size-bg;
}


.container-full {
    // max-width: 1140px;
    @include page-size-full;
}

main, section {
    min-height: 100vh;
}

section {
    margin-bottom: 6vh;
    padding-top: 40px;
}

strong {
    font-weight: 700;
}


.logo {
    max-width: 100px;
    a, span {
        display: block;
        font-family: $font-sec;
        font-weight: 500;
    }
    img {
        width: 100%;
        height: auto;
        transition: $trs;
    }
    
    &:hover {
        img{
            transform: scale(1.10);
            transition: $trs;
        }
        a, span {
            font-weight: 900;
        }
    }
}

.hidden {
    display: none;
}

.load-more {
    &__container {
        text-align: center;
    }
    &__button {
        min-width: 200px;
        @include breakpoint("mobile") {
            width: 100%;
        }
    }
}

[class*="page-"]{
    main {
        > div:first-child {
            padding-bottom: 40px;
        }
    }
}

.no-image {
    position: absolute;
    z-index: 2;
    display: block;
    width: 100%;
    height: calc(100% - 4px);
    text-align: center;
    font-size: 0;
    min-width: 220px;
    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: calc(100% - 4px);
        height: 100%;
    }
    &::before {
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: lighten($color-black, 20%);
        border: 2px dashed lighten($color-black, 40%);
        border-radius: 3px;
    }
    &::after {
        content: attr(alt);
        z-index: 2;
        top: 50%;
        right: 0;
        bottom: auto;
        left: 0;
        height: auto;
        color: lighten($color-black, 60%);
        font-size: 14px;
        font-weight: 500;
    }
}


.default-date {
    font-size: 14px;
    margin: 0 0 10px;
    color: #888888;
    i {
      color: #cccccc;
      margin-right: 7px;
    }
  }