/* sponsors Single & sponsors Page*/
.sponsors {
  font-family: $font-main-bold;
  padding: 80px 15px;
  
  &-page {
   
    .title {
      width: 100%;
      margin: 0 0 40px;
      position: relative;
      line-height: 1.5;
      h2,
      h3 {
        color: $color-main;
        position: relative;
        padding: 0 0 0 22px;
        font-size: calc(20px + 1vw);
        font-weight: 600;
        line-height: 40px;
        &:after {
          background-image: url(../../assets/image/titlelines_o.png);
          position: absolute;
          left: 0;
          top: 0;
          width: 10px;
          height: 37px;
          content: " ";
        }
      }
    }
    .sponsors {
      &-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        
        @include breakpoint("mobile") {
          flex-direction: column;
        }
      
        .sponsors-bar {
          background-color: $color-white;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);
          border-radius: 3px;
          position: relative;
          width: 32%;
          margin-bottom: 40px;
          margin-right: 1%;
          &:hover {
            transform: scale(1.05);
            transition: $trs;
            opacity: 1;
            .sponsors{
              &-logo {
                display: block;
              }                
              &-thumb {
                filter: grayscale(0) sepia(0) contrast(1.2);
              }
            }
          }
  
          &:last-child {
            margin-right: auto;
          }
          @include breakpoint("mobile") {
            max-width: 100%;
            width: 100%;
          }
  
          .sponsors-thumb {
            position: relative;
            overflow: hidden;
            @include breakpoint("mobile") {
              min-height: initial;
            }
            a {
              position: absolute;
              z-index: 10;
              display: block;
              height: 100%;
              width: 100%;
              &:before,
              &:after {
                transition: all ease-in-out 0.5s;
                -webkit-transition: all ease-in-out 0.5s;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 0;
                opacity: 0;
                content: "";
                background: rgba(0, 0, 0, 0.3);
              }
              &:before {
                top: inherit;
                bottom: 0;
              }
            }
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  &-list {
    &:hover {
      .sponsors-bar {
        transform: scale(0.95);
        transition: $trs;
        opacity: 0.8;
        .sponsors {
          &-thumb {
            filter: grayscale(0.4) sepia(0.6);
          }
        }
      }
    }
  }

  &-details {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 0;
    opacity: 0.9;
    .sponsors {
      &-logo {
        position: relative;
        z-index: 2;
        img {
          max-width: 90px;
        }
      }
      &-name {
      }
    }
  }
}
